import { createEndlessSongResource } from '#/components/SongList/createEndlessSongResource'
import { SongList } from '#/components/SongList/SongList'
import { WelcomeHeading } from '#/components/WelcomeHeading/WelcomeHeading'
import { t } from '#/i18n/I18nProvider'
import { createTrpcQuery } from '#/trpc/solidTrpc'

export default function Page() {
  const [userInfo] = createTrpcQuery({
    query: 'getUserInfo',
    globalId: 'userInfo',
  })

  const pseudoRandomSort = String.fromCharCode(
    Math.round(Math.round(Math.random() * 25) + 65),
  )

  const endlessSongResource = createEndlessSongResource(() => ({
    pseudoRandomSort,
  }))

  return (
    <>
      <WelcomeHeading>
        {t('home.welcome', { name: userInfo()?.givenName ?? '' })}
      </WelcomeHeading>

      <SongList endlessSongResource={endlessSongResource} />
    </>
  )
}
